<template>
  <div class="wrapper">
    <Suspense>
      <template #default>
        <Logins />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </div>
</template>

<script>
import { Suspense } from "vue";
import Loading from "@/components/Spinners/Loading.vue";
import Logins from "@/components/Users/Logins.vue";
export default {
  components: {
    Logins,
    Loading,
    Suspense,
  },
};
</script>

<style>
</style>