<template>
 <!--Content start-->
 <div class="header">

            <!--Content before waves-->
            <div class="inner-header flex">
                <!-- <img src="../../assets/img/Zain_Group-Logo.png" alt=""/> -->
                <img class="header-logo img-fluid mb-2 scrMob" src="../../assets/img/zain_gray.png" alt="footer logo">
            </div>

            <!--Waves Container-->
            <div>
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
                </svg>
            </div>
            <!--Waves end-->

        </div>
        <!--Header ends-->
    <main id="content">
       <div class="content flex">
            <form >
                <p class="sub-title mb-3 text-center zoal arabic-kufi" style="font-size:  18px; width:80%; font-weight: bold inherit;">اشترك الآن واستمتع بتجربة فريدة من منصة تأق برس الاخبارية</p>
                <a @click="Subscrib()" class="login-btn pointer" style="padding-top: 10px;">اشتراك</a>
                <router-link class="login-btn" style="padding-top: 10px;"  to="/LoginUser">تسجيل الدخول</router-link>
                <!-- <button class="login-btn" style="vertical-align: middle; font-weight: bold;">تسجيل الدخول</button> -->
                <!-- <p class="arabic-kufi" style="width:80%;"> قيمة الاشتراك اليومي 100 جنيه فقط شاملة الضريبة تجدد يوميا عبر شبكة زين </p> -->
            </form>
            <div class="bottom-container">
            </div>
        </div>
    </main>
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'LoginShow',
  mounted() {
    if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
        return 
      } else {
         return this.$router.go(-1)
      }
    },
    setup() {
        useMeta({ title: 'اشتراك' })
        const Subscrib = () => {
            // window.location.href = "http://test.zaindsp.com:3033/?p=2136397556";
            window.open("https://dsplp.sd.zain.com/?p=3885574267", "_self");
        }
        return { Subscrib }
    }
}
</script>

<style scoped>
            h3 {
                font-family: 'Cairo', sans-serif;
                font-weight:600;
                letter-spacing: 2px;
                font-size:32px;
            }
            p {
                font-family: 'Zain_Regular', sans-serif;
                letter-spacing: 0.1px;
                font-size:14px;
                color: #333333;
            }

            .header {
                position:relative;
                text-align:center;
                /* background: linear-gradient(60deg, rgb(18, 5, 68) 0%, rgba(20, 0, 255, 0.48) 100%); */
                background-image: linear-gradient(0deg, #00878C 0, #0FABA0);
                color:white;
            }
            .inner-header img{
                width:20%;
                position: relative;
                margin-top: 6%;

            }


            .inner-header {
                height:20vh;
                width:100%;
                margin: 0;
                padding: 0;
            }

            .flex { /*Flexbox for containers*/
                display: flex;
                flex-direction:column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .bottom-container{
                display:flex;
                align-items:center;
            }

            a{
                text-decoration:none;
                margin: 0px 5px;
                font-family: 'Zain_Regular', sans-serif;
                letter-spacing:0px;
                font-size:14px;
                font-weight:600;
                color: #ffffff;

            }

            .waves {
                position:relative;
                width: 100%;
                height:15vh;
                margin-bottom:-7px; /*Fix for safari gap*/
                min-height:100px;
                max-height:150px;
            }

            .content {
                position:relative;
                height:100%;
                text-align:center;
                background-color: white;
            }

            /* Animation */

            .parallax > use {
                animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
            }
            .parallax > use:nth-child(1) {
                animation-delay: -2s;
                animation-duration: 7s;
            }
            .parallax > use:nth-child(2) {
                animation-delay: -3s;
                animation-duration: 10s;
            }
            .parallax > use:nth-child(3) {
                animation-delay: -4s;
                animation-duration: 13s;
            }
            .parallax > use:nth-child(4) {
                animation-delay: -5s;
                animation-duration: 20s;
            }
            @keyframes move-forever {
                0% {
                    transform: translate3d(-90px,0,0);
                }
                100% { 
                    transform: translate3d(85px,0,0);
                }
            }
            
            
            

            form{
                display:flex;
                width:90%;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                padding:10px;
                margin:10%;
            }

            input{
                width:40%;
                padding:8px;
                margin:10px;
                height:45px;
                font-size:1rem;;
                border:none;
                box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
                border-radius:12px;
                outline:none;
                text-align:center;
                font-family: 'Zain_Regular', sans-serif;
                color: #2A4A8B;

            }

            .login-btn{
                font-size:1rem;
                border:none;
                height:44px;
                width:40%;
                margin:20px 10px;
                border-radius:10px;
                /* background-color:#2A4A8B; */
                background-image: linear-gradient(0deg, #00878C 0, #0FABA0);
                font-family: 'Zain_Regular', sans-serif;
                color:#ffffff
                
            }

            button:hover {
                box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);

            }

            /*Shrinking for mobile*/
            @media (max-width: 768px) {
                .form{
                    max-width:80vh;
                }
                .waves {
                    height:40px;
                    min-height:40px;
                }
                .content {
                    height:50vh;
                }
                h1 {
                    font-size:24px;
                }
                .inner-header img {
                    width: 35%;
                    position: relative;
                    margin-top: 6%;
                }
                input{
                    width: 60%;
                    
                } 
                .login-btn{
                width: 50%;
                margin: 10px 10px;
                }
                .inner-header {
                height:30vh;
                }
            }

            @media (max-width: 516px) {
                .form{
                    max-width:50vh;
                }
                .waves {
                    height:40px;
                    min-height:40px;
                }
                .content {
                    height:50vh;
                }
                h1 {
                    font-size:24px;
        }
        .inner-header img {
            width: 30%;
                    
        }
        input{
            width: 90%;
        }   
        .login-btn {
            width: 90%;
            margin: 10px 10px;
        }
    }

.pointer {
  cursor: pointer;
}
</style>